import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  invoiceData: any;
  anchorEl: any; 
  openDownloadModal: boolean;
  openCreateInvoiceModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      openCreateInvoiceModal: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      anchorEl: "",
      openDownloadModal: false,
      invoiceData: [
        {
          "clientName": "Jerome Bell",
          "projectName": "HQ Renovation",
          "room": "Living Room",
          "amount": 5000,
          "dueDate": "14/06/2024",
          "status": "UPCOMING"
        },
        {
          "clientName": "Jerome Bell",
          "projectName": "Downtown Apartment",
          "room": "Kitchen",
          "amount": 7500,
          "dueDate": "21/06/2024",
          "status": "UPCOMING"
        },
        {
          "clientName": "Jerome Bell",
          "projectName": "Office Remodeling",
          "room": "Main Office",
          "amount": 15000,
          "dueDate": "10/07/2024",
          "status": "PAID"
        },
        {
          "clientName": "Jacob Jones",
          "projectName": "Eco Project",
          "room": "Bathroom 1",
          "amount": 12000,
          "dueDate": "05/06/2024",
          "status": "PAID"
        },
        {
          "clientName": "Jacob Jones",
          "projectName": "Lakeview House",
          "room": "Verandah",
          "amount": 8250,
          "dueDate": "19/06/2024",
          "status": "PAID"
        },
        {
          "clientName": "Ronald Richards",
          "projectName": "Skyline Expansion",
          "room": "Conference",
          "amount": 25000,
          "dueDate": "01/08/2024",
          "status": "UPCOMING"
        },
        {
          "clientName": "Ronald Richards",
          "projectName": "Resort Development",
          "room": "Bathroom 2",
          "amount": 6800,
          "dueDate": "11/06/2024",
          "status": "UNPAID"
        },
        {
          "clientName": "Ronald Richards",
          "projectName": "Luxury Villa Project",
          "room": "Kitchen",
          "amount": 11500,
          "dueDate": "15/07/2024",
          "status": "UNPAID"
        },
        {
          "clientName": "Esther Howard",
          "projectName": "Mountain Lodge",
          "room": "Guest Room",
          "amount": 9400,
          "dueDate": "20/06/2024",
          "status": "PAID"
        },
        {
          "clientName": "Esther Howard",
          "projectName": "City Center Mall",
          "room": "Entrance",
          "amount": 18000,
          "dueDate": "25/06/2024",
          "status": "UPCOMING"
        }
      ]
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pdfApiCallId !== null &&
      this.pdfApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson1 && responseJson1.invoice) {
        this.setState({
          invoicePdf: responseJson1.invoice,
          loading: false,
        });
        window.open(responseJson1.invoice);
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson1);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    // Customizable Area End
  }

  // Customizable Area Start

 onChangeInvoice = (text: string) => {
    if (text === "") {
      this.setState({
        invoice: text,
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ invoice: text, invoiceError: "" });
    }

  };

  getPdfLink = () => {
    if (this.state.invoice === "" || this.state.invoice.length !== 6) {
      this.setState({
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.pdfApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.exampleAPiEndPoint + this.state.invoice
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event?.currentTarget })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  openDownloadModal = () => {
    this.setState({
      openDownloadModal: true
    })
  };

  openCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: true
    })
  };
  closeCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: true
    })
  };
  closeDownloadModal = () => {
    this.setState({
      openDownloadModal: false
    })
  };
  // Customizable Area End
}
