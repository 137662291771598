//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Input,
  Select,
  Grid,
  MenuItem,
  styled,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  Collapse,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  Popper,
  PopperProps,
  Checkbox,
  ListItemText,
  Dialog,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  OutlinedInput,
  IconButton,
  Select as data
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import 'react-circular-progressbar/dist/styles.css';
import { KeyboardArrowDown } from "@mui/icons-material";
import { Formik } from "formik";
import {
  Add,
  ArrowBackIos,
  Check,
  ControlPointDuplicateSharp,
  DeleteOutline,
  ExpandMore,
  Remove,
  Search,
} from "@material-ui/icons";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import Calendar from 'react-calendar';
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";
import {
  unCheckIcon,
  checkedIcon,
  threeDotIcon,
  paidIcon,
  unPaidIcon,
  upComingIcon,
  filterIcon,
  cross
} from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInvoiceSummary = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          borderRadius: "12px",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          margin: "20px",
          padding: "20px",
          alignItems: "center",
          gap: { xs: "20px", md: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "30px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              fontFamily: "Poppins",
              lineHeight: "26px",
            }}
          >
            Invoice Summary
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ height: "62px", width: "62px" }}>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  pathColor: "#10B981",
                  trailColor: "#D1FAE5",
                })}
                strokeWidth={"8"}
                value={54}
              >
                <img
                  style={{ width: 26, height: 26, marginTop: -5 }}
                  src={paidIcon}
                />
              </CircularProgressbarWithChildren>
            </Box>
            <Box>
              <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
                Paid Invoices:{" "}
                <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                  54
                </span>
              </Typography>
              <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
                Total Amount:{" "}
                <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                  £125,342
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#FCD34D",
                trailColor: "#FEF3C7",
              })}
              strokeWidth={"8"}
              value={31}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={upComingIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Upcoming Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                38
              </span>
            </Typography>
            <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
              Total Amount:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                £65,117
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#EF4444",
                trailColor: "#FEE2E2",
              })}
              strokeWidth={"8"}
              value={38}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={unPaidIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Unpaid Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                31
              </span>
            </Typography>
            <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
              Total Amount:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                £79,772
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  renderTableUperDiv = () => {
    return (
      <Box sx={webStyle.flex}>
        <Box sx={webStyle.searchBox}>
          <TextField
            variant="outlined"
            placeholder="Search here..."
            data-test-id="search-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    style={{ height: "20px", color: "#94A3B8", width: "20px" }}
                  />
                </InputAdornment>
              ),
              endAdornment: this.state.searchValue && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.clearSearch}
                    data-test-id="clear_search_id"
                    disableRipple
                    style={{ padding: 0, cursor: "pointer" }}
                  >
                    <ClearIcon
                      style={{
                        color: "#94A3B8",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                height: "32px",
                borderRadius: "8px",
                border: "1px solid #CBD5E1",
                width: "220px",
                '& ::placeholder': {
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#94A3B8"
                },
              },
            }}
            style={{
              marginRight: "12px",
              borderRadius: "8px",
              backgroundColor: "white",
              height: "32px",
            }}
          />
          <Box
            style={{
              display: "flex",
              gap: "8px",
              flexWrap: "wrap", 
            }}
          >
            <Button
              testID="bulkButton"
              sx={{
                backgroundColor: "#F1F5F9",
                height: "32px",
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#94A3B8",
                textTransform: "none",
              }}
              variant="outlined"
              endIcon={<KeyboardArrowDown />}
              onClick={this.handleMenuOpen}
            >
              Bulk Action
            </Button>
            <Menu
              testID="bulkOptionDropdown"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                },
                "& .MuiMenu-list": {
                  padding: "0px 0px"
                }
              }}
            >
              <MenuItem
                testID = "downlaodModal"
                onClick={() => this.openDownloadModal()}
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  color: "#0F172A",
                  width: "144px",
                  height: "46px",
                  borderRadius: "8px"
                }}
              >
                Download
              </MenuItem>
            </Menu>
            <FilterButton data-test-id="handleButtonScaling">
              <img width={20} height={20} src={filterIcon} alt="Filter" />
              Filter
            </FilterButton>
          </Box>
  
          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", marginLeft: "15px" }}
          >
            *Open the invoice to change the status
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap"
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
            Total Invoices: 123
          </Typography>
          <Button
            testID="createInvoiceButton"
            onClick={this.openCreateInvoiceModal}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#237182",
              color: "white",
              fontFamily: "Poppins",
              fontWeight: 600,
              height: "44px",
              fontSize: "16px",
              lineHeight: "24px",
              borderRadius: "8px",
              padding: "10px 12px",
              textTransform: "capitalize",
            }}
          >
            <span style={webStyle.createIcon}>
              <AddCircleOutlineOutlinedIcon />
            </span>
            Create Invoice
          </Button>
        </Box>
      </Box>
    );
  };
  
  renderMembersList = () => {
    return this.state.invoiceData.map((invoiceList, index) => (
      <TableRow key={index}>
        <TableCell style={webStyle.SubDivC}>
          <Checkbox
            data-test-id="checkbox-change-data-id"
            icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
            checkedIcon={
              <img src={checkedIcon} style={{ width: 16, height: 16 }} />
            }
            style={{ color: "#64748B" }}
          />
        </TableCell>
        <TableCell
          style={{ ...webStyle.SubDivC }}
        >{invoiceList.clientName}</TableCell>
        <TableCell style={{ ...webStyle.SubDivC }}>{invoiceList.projectName}</TableCell>
        <TableCell style={{ ...webStyle.SubDivC }}>{invoiceList.room}</TableCell>
        <TableCell
          style={{
            ...webStyle.SubDivC,
            position: "relative",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
            }}
          >
            {invoiceList.amount}
          </Typography>
        </TableCell>
        <TableCell style={{ ...webStyle.SubDivC }}>
          {invoiceList.dueDate}
        </TableCell>
        <TableCell style={{ ...webStyle.SubDivC, }}>
          <Box sx={{
            backgroundColor: invoiceList.status === "PAID" ? "#D1FAE5" : invoiceList.status === "UNPAID" ? "#FEE2E2" : "#FEF3C7",
            borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center",
            color: invoiceList.status === "PAID" ? "#059669" : invoiceList.status === "UNPAID" ? "#DC2626" : "#D97706"
          }}>
            {invoiceList.status}
          </Box>
        </TableCell>
        <TableCell
          style={{
            ...webStyle.SubDivC,
          }}
        >
          <Typography
            data-test-id="handleClickMember_id"
            className="hoverEffect"
          >
            <MoreVertIcon style={{ cursor: "pointer" }} />
          </Typography>
        </TableCell>
      </TableRow>
    ));
  };

  renderTable = () => {
    return (
      <TableContainer style={webStyle.SubTableBox}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  data-test-id="selecting-data-id"

                  icon={
                    <img
                      src={unCheckIcon} style={{ width: 16, height: 16 }} />
                  }
                  checkedIcon={<img
                    src={checkedIcon}
                    style={{ width: 16, height: 16 }}
                  />}
                  style={
                    { color: "#64748B" }
                  }
                />
              </TableCell>
              <TableCell style={webStyle.TableTitleText}>Client Name</TableCell>
              <TableCell style={webStyle.TableTitleText}>Project Name</TableCell>
              <TableCell style={webStyle.TableTitleText}>Room</TableCell>
              <TableCell style={webStyle.TableTitleText}>Amount</TableCell>
              <TableCell style={webStyle.TableTitleText}>Due Date</TableCell>
              <TableCell style={webStyle.TableTitleText}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderMembersList()}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  renderPagination = () => {
    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footerSecond}>
          <Box style={webStyle.footerThird}>
            <ChevronLeftIcon
              style=
              {
                {
                  ...webStyle.footerIcon,
                  width: "24px",
                  height: "24px",
                }
              }
              data-test-id="pagination-click-id"
            />
            {
              [1, 2, 3, 4].map((page: any, index) =>
              (
                <Box key={index}
                  sx={{ marginLeft: "8px", marginRight: "8px" }}>
                  <Typography
                    style={
                      {
                        ...webStyle.footerFont,
                        cursor: page === "..." ?
                          "default" :
                          "pointer",
                          "bold": "normal",
                      }}
                  >
                    {page}
                  </Typography>
                </Box>
              ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
          <Typography style={webStyle.footer3Style}>
            1 - 10 of 123 results
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDownloadModal = () => {
    return (
      <AddTaskDialog
        data-test-id="downlaodModal"
        onClose={this.closeDownloadModal}
        open={this.state.openDownloadModal}
      >
        <Box sx={{margin: "10px"}}>
          <Box sx={{display: "flex", }}>
            <Typography
              sx={{
                color: "#0F172A",
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                fontFamily: "Poppins",
              }}
            >
              Do you want to download your invoices?
            </Typography>
            <img onClick={this.closeDownloadModal}
                  style={{ width: 11, height: 11, marginTop: "8px", marginLeft: "8px"}}
                  src={cross}
                />
          </Box>
          <Typography
            sx={{
              color: "#1E293B",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Poppins",
              marginTop: "10px"
            }}
          >
            Click 'Download' to save your invoices to your device.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              flexWrap: "wrap",
              marginTop: "30px"
            }}
          >
            <Button
              onClick={this.closeDownloadModal}
              data-test-id="cancelButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#E0EDF0",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                lineHeight: "24px",
                padding: "10px 16px",
                textTransform: "none"
              }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="downloadButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#237182",
                color: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                padding: "10px 16px",
                textTransform: "capitalize",
                textTransform: "none"
              }}
            >
              Download
            </Button>
          </Box>
        </Box>
      </AddTaskDialog>
    );
  };

  renderCreateInvoiceModal = () => {
    return (
      <AddTaskDialog
        testID="createInvoiceModal"
        onClose={this.closeCreateInvoiceModal}
        open={this.state.openCreateInvoiceModal}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "24px", fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins' }}>
            Create Invoice
          </span>
          <CloseIcon
            style={{ cursor: "pointer", width: '20px', height: '20px' }}
            onClick={this.handleAddClick}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="Task" style={webStyle.addTaskPopupHeadings}>Invoice Name</label>
          <TextField
            fullWidth
            id="taskName"
            data-test-id={"taskNameNew"}
            name="taskName"
            placeholder="Enter task here"
            variant="outlined"
            InputProps={{
              sx: {
                '& ::placeholder': {
                  color: "#94A3B8",
                  fontSize: "16px",
                  weight: 400,
                  fontFamily: "Poppins"
                },
                input: {
                  color: "#0F172A",
                },
              },
            }}
            sx={webStyle.textFieldError}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="MeasurementUnit" style={webStyle.addTaskPopupHeadings}>Measurement Unit:</label>
          <MeasurementUnitField>
            <FormControl fullWidth>
              <Select
                id="measurementUnit"
                name="measurementUnit"
                data-test-id={"QtyNew"}
                fullWidth
                displayEmpty
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#E0EDF0"
                      },
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#E0EDF0",
                      }
                    }
                  }
                }}
              >
                <MenuItem value="Unit 1">Unit 1</MenuItem>
                <MenuItem value="Unit 2">Unit 2</MenuItem>
              </Select>
            </FormControl>
          </MeasurementUnitField>

        </Grid>
        <Grid item xs={6}>
          <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>Labour Cost / Unit:</label>
          <TextField
            fullWidth
            id="labourCostUnit"
            data-test-id={"labourCostUnitNew"}
            name="labourCostUnit"
            placeholder="Enter Cost"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ color: "#64748B", fontWeight: 600 }}>£</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              sx: {
                '& ::placeholder': {
                  color: "#94A3B8",
                  fontSize: "16px",
                  weight: 400,
                  fontFamily: "Poppins"
                },
                input: {
                  color: "#0F172A"
                },
              },
            }}
            sx={webStyle.textFieldError}
          />
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            flexWrap: "wrap",
            marginTop: "30px"
          }}
        >
          <Button
            onClick={this.closeDownloadModal}
            data-test-id="cancelButton"
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#E0EDF0",
              color: "#325962",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              lineHeight: "24px",
              padding: "10px 16px",
              textTransform: "none"
            }}
          >
            Cancel
          </Button>
          <Button
            data-test-id="downloadButton"
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#F1F5F9",
              color: "#64748B",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              padding: "10px 16px",
              textTransform: "capitalize",
              textTransform: "none"
            }}
          >
            Create
          </Button>
        </Box>
      </AddTaskDialog>
    );
  };
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    return (
          <Box style={{ background: "#f0f6f7", height: "100%" }}>
            <MyProfileHeader
              navigation={this.props.navigation}
              tabName="Users&Teams"
            >
              {this.renderCreateInvoiceModal()}
              {this.renderDownloadModal()}
              {this.renderInvoiceSummary()}
              {this.renderTableUperDiv()}
              {this.renderTable()}
              {this.renderPagination()}
            </MyProfileHeader>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  textFieldError: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0
    }
  },
  footerFont: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3Style: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: "20px 40px",
    boxShadow: 1,
  },
  footerSecond: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerThird: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    marginLeft: "35px",
    color: "#94A3B8",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  progressNumber: {
    fontWeight: 700,  
    fontFamily: "Poppins", 
    lineHeight: "26px"
  },

  progressText: {
    fontWeight: 400, 
    fontSize: 16, 
    lineHeight: "24px",
    fontFamily: "Poppins", 
  
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
    flexWrap: "wrap", 
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  SubDivC: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
  },

  createIcon: {
    marginRight: "10px",
    width: "24px",
    height: "24px"
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px"
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
  },

  addTaskPopupHeadings: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#475569',
  }
};

const Measurementunit = styled(TableCell)({
  "& .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: '8px',
    color: '#94A3B8',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    width: '95px'
  },
  "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root": {
    padding: '3px 8px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    width: '95px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  },
  '& .css-ka7ti6-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    minWidth: '42px'
  }

});

const AddTaskDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '35px 24px',
    boxShadow: 'none'
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },

});

const FilterButton = styled(Button)({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  color: "#0F172A",
  fontWeight: 400,
  fontSize: "16px",
  height: "32px",
  width: "90px",
  letterSpacing: 0,
  lineHeight: "22px",
  textTransform: "none",
  padding: "10px 10px 10px 10px",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Poppins",
  cursor: "pointer",
});

const MeasurementUnitField = styled(Box)({
  '& .MuiOutlinedInput-root': {
    paddingRight: '10px',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }
});

// Customizable Area End
