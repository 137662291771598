import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";


interface Team {
  id: number;
  type: string;
  attributes: TeamAttributes;
}

interface TeamAttributes {
  team_name: string;
  created_at: string;
  account: Account;
  members_count: number;
}

interface Account {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string | null;
  country_code: string | null;
  phone_number: number | null;
  email: string | null;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string | null;
  password_digest: string | null;
  created_at: string | null;
  updated_at: string | null;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: number;
  role_id: string | null;
  role: string | null;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  tnc_status: boolean;
  company_name: string | null;
  job_title: string | null;
  deleted_at: string | null;
  company_number: string | null;
  vat: string | null;
  business_name: string | null;
  business_address: string | null;
  billing_address: string | null;
  street_name: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  city: string | null;
  about_me: string | null;
  deactivated_at: string | null;
  deletion_status: string | null;
  deletion_timestamp: string | null;
  deletion_attempt_locked_until: string | null;
  parent_id: string | null;
  notify: boolean;
  invited_status: boolean;
}
interface TeamMembersList {
  attributes:
  {
  first_name: string;
  last_name:string
  }
}
interface Member {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string | null;
  country_code: string | null;
  phone_number: number | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string | null;
  password_digest: string | null;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: number;
  role_id: string;
  role: string | null;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  tnc_status: boolean;
  company_name: string | null;
  job_title: string;
  deleted_at: string | null;
  company_number: string | null;
  vat: string | null;
  business_name: string | null;
  business_address: string | null;
  billing_address: string | null;
  street_name: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  city: string | null;
  about_me: string | null;
  deactivated_at: string | null;
  deletion_status: string;
  deletion_timestamp: string | null;
  deletion_attempt_locked_until: string | null;
  parent_id: number;
  notify: boolean;
  invited_status: boolean;
}
interface TeamAdded{
  id:  number | null;
	team_name: string | null;
	account_id: number | null;
  created_at: string | null;
  updated_at: string | null;
}
interface MemberData {
  id:number;
  first_name:string;
  email:string;
  team_anme:string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:any;
  projectId:string;
  planId:any;
  close: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  projectId:string;
  planId:string;
  openDialog:boolean;
  toggle:string;
  viewAddedMemeber:boolean;
  searchValue: string;
  selectedItems: string[];
  selectedTeamId:string | null;
  getAllTeam:Team[];
  getAllMembers:Member[];
  getAllAddedMemeber: {
    members: Member[];
    teams: TeamAdded[];
};
searchFieldError:string;
selectedOptions:[];
teamsMembersList:TeamMembersList[];
membersData: MemberData[];
searchText: string;
selectedMembers: Member[];
selectedTeams:Team[]
isDialogOpen: boolean;

  // Customizable Area End

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAndViewMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeamsListCallId:string = "";
  apiGetMembersListCallId:string = "";
  apiGetAddedMembersListCallId:string = ""; 
  apiDeleteMemberByIdCallId:string = "";
  apiDeleteTeamByIdCallId:string = "";
  apiAddMemberCallId:string="";
  apiAddTeamCallId:string="";
  getTeamMembersListByIdCallId:string="";
  items: string[] = ["Apple", "Banana", "Cherry", "Date", "Elderberry"];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token:'',
      projectId:'',
      planId:'',
      openDialog:false,
      toggle:'Teams',
      viewAddedMemeber:false,
      searchValue: "",
      selectedItems: [],
      selectedTeamId:'',
      getAllTeam:[],
      getAllMembers:[],
      getAllAddedMemeber:{members: [],teams: []},
      searchFieldError:'',
      selectedOptions:[],
      teamsMembersList:[],
      membersData: [],
      searchText: "",
      selectedMembers: [],
      selectedTeams: [],
      isDialogOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetAddedMembersListCallId) {
        this.getAddedMembersListResponse(responseJson);
       
      }
    }

   
  }
  this.receive1(message);
  this.receive2(message);
  this.receive3(message);
  this.receive4(message);
  this.receive5(message);
  this.receive6(message);
  this.receive7(message);
   // Customizable Area End
    }
  // Customizable Area Start
  async componentDidMount() {
    let token = await getStorageData("token");
    this.setState({ token: token });
    this.getIds();
    this.handleGetAllTeamList();
    this.handleGetAllMemberList();
    this.handleGetAddedMembersList();
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedTeamId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedTeamId: null });
  }
};
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTeamsListCallId) {
          this.getTeamsListResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetMembersListCallId) {
          this.getMembersListResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive3=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiAddTeamCallId) {
          this.addTeamResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive4=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiAddMemberCallId) {
          this.addMemberResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive5=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDeleteTeamByIdCallId) {
          this.deleteTeamByIdResponse(responseJson);
        }
      }
  
     
    }

  }
  receive6=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDeleteMemberByIdCallId) {
          this.deleteMemberByIdResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive7=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getTeamMembersListByIdCallId) {
          this.getTeamMembersListByIdResponse(responseJson);
         
        }
      }
  
     
    }

  }


  getIds() {
    
    let url = window.location.pathname;
    
    let parts = url.split("/");
  
    if (parts.length >= 3) {const planId = parts[2];
      if (parts.length >= 5 && parts[3] === "project_id") {
        const projectId = parts[4];
        this.setState({ 
           planId: planId,
           projectId: projectId });
        return { 
          planId, 
          projectId };
      }
     
      this.setState({ planId: planId,
         projectId: '' });
      return { planId, 
        projectId: null };
    }
    return { planId: null, 
      projectId: null };
  }

handleCloseDialog=()=>{
  this.setState({openDialog:false})
}
handleToggle=(dataName:string)=>{
   
    this.setState({toggle:dataName,viewAddedMemeber:false,searchFieldError:'',selectedItems:[],searchValue:'',selectedOptions:[]})
    
   
    if(dataName==="Members"){
       this.handleGetAllMemberList();
    }

}
handleViewAddedMemeber=()=>{
    this.setState({
        viewAddedMemeber : !this.state.viewAddedMemeber,
        toggle:''
        
    },()=>{
      this.state.viewAddedMemeber?this.setState({toggle:''}):this.setState({toggle:'Teams'})
    })
}

 
  handleOpenMembersList=(id:string)=>{
    this.setState(prevState => ({
        selectedTeamId: prevState.selectedTeamId === id ? null : id
    }));
    this.getTeamsMemberList(id);

  }
  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  };

  clearSearch = () => {
    this.setState({ searchText: "", selectedMembers:[], selectedTeams:[],searchFieldError:'' });
  };

  toggleSelectMember = (member: any) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedMembers.some(
        (m) => m.id === member.id
      );

      const updatedSelectedMembers = isSelected
        ? prevState.selectedMembers.filter((m) => m.id !== member.id)
        : [...prevState.selectedMembers, member];

      return { selectedMembers: updatedSelectedMembers };
    });
  };

  toggleSelectTeam = (team: any) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedTeams.some(
        (m) => m.id === team.id
      );

      const updatedSelectedTeams = isSelected
        ? prevState.selectedTeams.filter((m) => m.id !== team.id)
        : [...prevState.selectedTeams, team];

      return { selectedTeams: updatedSelectedTeams };
    });
  };

  removeChipMember = (id: number) => {
    this.setState((prevState) => ({
      selectedMembers: prevState.selectedMembers.filter((m) => m.id !== id),
      searchText: "",
      searchFieldError:''
    }));
  };

  removeChipTeam = (id: number) => {
    this.setState((prevState) => ({
      selectedTeams: prevState.selectedTeams.filter((m) => m.id !== id),
      searchText: "",
       searchFieldError:''
    }));
  };


  handleKeyDownMember = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { searchText, selectedMembers, getAllMembers } = this.state;

    if (event.key === "Backspace" && searchText === "" && selectedMembers.length > 0) {
      const updatedSelectedMembers = [...selectedMembers];
      updatedSelectedMembers.pop();
      this.setState({ selectedMembers: updatedSelectedMembers });
    }

    if (event.key === "Enter") {
      const filteredMembers = getAllMembers.filter((member) =>
        member.first_name.toLowerCase().includes(searchText.toLowerCase())
      );

      if (filteredMembers.length > 0) {
        this.toggleSelectMember(filteredMembers[0]);
        this.setState({ searchText: "" });
      }
    }
  };
  handleKeyDownTeam = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { searchText, selectedTeams, getAllTeam } = this.state;

    if (event.key === "Backspace" && searchText === "" && selectedTeams.length > 0) {
      const updatedSelectedTeams = [...selectedTeams];
      updatedSelectedTeams.pop();
      this.setState({ selectedTeams: updatedSelectedTeams });
    }

    if (event.key === "Enter") {
      const filteredTeams = getAllTeam.filter((member) =>
        member.attributes.team_name.toLowerCase().includes(searchText.toLowerCase())
      );

      if (filteredTeams.length > 0) {
        this.toggleSelectTeam(filteredTeams[0]);
        this.setState({ searchText: "" });
      }
    }
  };

  handleAdd = () => {
    const { selectedMembers } = this.state;
    console.warn("Selected Members: ", selectedMembers);
  };
    
  handleGetAllMemberList=()=>{
    const header = {
        "Content-Type": configJSON.getContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetMembersListCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getMembersListAPIEndPoint}?project_id=${this.state.projectId}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleGetAllTeamList=()=>{
    const header = {
        "Content-Type": configJSON.getContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetTeamsListCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTeamsListAPIEndPoint}?project_id=${this.state.projectId}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  handleGetAddedMembersList=()=>{
    const header = {
        "Content-Type": configJSON.getContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetAddedMembersListCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAddedMembersListAPIEndPoint}?project_id=${this.state.projectId}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getTeamsListResponse=(responseJson:any)=>{
    this.setState({
      getAllTeam:responseJson.data
    })
  }
  getMembersListResponse=(responseJson:any)=>{
    this.setState({
      getAllMembers:responseJson.members
    })
  }
  getAddedMembersListResponse=(responseJson:any)=>{
    this.setState({
      getAllAddedMemeber:responseJson
    })
  }
  handleAddMember=(memberArr:any)=>{
    const ids = memberArr.map((item:any) => item.id);
   
    let numberArray = ids.map(Number);
    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token,
    };
    const body ={
      project_id: this.state.projectId,
      member_ids: numberArray
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddMemberCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAddMemberAPIEndPoint}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleAddTeam=(teamArr:any)=>{
const ids = teamArr.map((item:any) => item.id);

    let numberArray = ids.map(Number);


    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token,
    };
    const body ={
      project_id: this.state.projectId,
      team_ids: numberArray
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddTeamCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAddTeamAPIEndPoint}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  deleteMemberById=(memberId:any)=>{

    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteMemberByIdCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDeleteMemberByIdAPIEndPoint}/${memberId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  deleteTeamById = (memberId: any) => {

    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteTeamByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDeleteTeamByIdAPIEndPoint}/${memberId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  addTeamResponse=(responseJson:any)=>{
    const error = responseJson?.error
    if(error){this.setState({searchFieldError:responseJson.error,searchValue:'',selectedItems:[],selectedOptions:[],selectedTeams:[]}) 
     }else
    {  
      if(responseJson.message){
        toast.error(responseJson.message, {position: "top-center",
          autoClose: 5000,hideProgressBar: true,
          closeOnClick: false,pauseOnHover: false,
          draggable: false,closeButton: false,
          theme: "light",});
        this.setState({searchValue:'',selectedItems:[],selectedOptions:[],searchFieldError:'',selectedTeams:[],isDialogOpen:false})
        this.handleGetAddedMembersList();
       
      }
      }
    }

  addMemberResponse=(responseJson:any)=>{
    const error = responseJson?.error
  
    if(error){
      
      this.setState({searchFieldError:responseJson.error}) 
      this.setState({searchValue:'',selectedItems:[],selectedOptions:[],selectedMembers:[]})
    }else{  

      if(responseJson.message){
        toast.error(responseJson.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
        });
        this.setState({searchValue:'',selectedItems:[],selectedOptions:[],searchFieldError:'',selectedMembers:[],isDialogOpen:false})
        this.handleGetAddedMembersList();
      }
   
    }
  }
  deleteTeamByIdResponse=(responseJson:any)=>{
 
    if(responseJson.message){
    toast.error(responseJson.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      closeButton: false,
      theme: "light",
    });
    this.setState({searchValue:'',selectedItems:[],selectedOptions:[]})
    this.handleGetAddedMembersList();
  }

  }
  deleteMemberByIdResponse=(responseJson:any)=>{
   
    if(responseJson.message){
      toast.error(responseJson.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
    }
    this.setState({searchValue:'',selectedItems:[],selectedOptions:[]})
    this.handleGetAddedMembersList();

  }
  getTeamsMemberList = (TeamId: any) => {

    const header = {
      "Content-Type": configJSON.getContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeamMembersListByIdCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getTeamMembersListAPIEndPoint}/${TeamId}/list_members`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 getTeamMembersListByIdResponse=(responseJson:any)=>{

  this.setState({teamsMembersList:responseJson.data})
 }
 getMemberPlaceholder = () => {
  const { selectedMembers } = this.state;
  return selectedMembers.length === 0 
    ? "Search by name or email..."
    : "";
};
getTeamPlaceholder = () => {
  const { selectedTeams } = this.state;
  return selectedTeams.length === 0
    ? "Search by name or email..."
    : "";
};
  handleAddButtonClick = () => {
    const { toggle, selectedTeams, selectedMembers } = this.state;

  if (toggle === "Teams") {
    this.handleAddTeam(selectedTeams);
  } else {
    this.handleAddMember(selectedMembers);
  }
};
handleMemberListOpen = (member:any) => {
  if (member.attributes.members_count !== 0) {
    this.handleOpenMembersList(String(member.id));
  }
};
getFilteredMember = (filteredMembers: any, selectedMembers: any) => {
  return filteredMembers
    .filter(
      (member: any) =>
        !selectedMembers.some(
          (selectedMember: any) => selectedMember.id === member.id
        )
    )
}
  // Customizable Area End
}
