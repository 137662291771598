import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { Country, State } from "country-state-city";

interface ProjectDetails{
  projectName:string;
  clientFirstName:string;
  clientLastName:string;
  clinetEmail : string;
  clientStreetName:string;
  clientCityName: string;
  clientStateName: string | undefined;
  clientCountryName: string | undefined;
  clientPostCode : string;
  totalCost:string;
  adjustedCost:string;
}
interface TaskAttributes {
  id: number;
  account_id: number | null;
  name: string;
  created_at: string;
  updated_at: string;
  tasks: Array<{
    id: number;
    title: string;
    description: string;
    status: string;
    priority: string | null;
    created_at: string;
    updated_at: string;
    task_list_id: number;
    assigned_to: string | null;
  }>;
}

interface TaskListDataItem {
  data: Array<{
    id: number;
    type: string;
    attributes: TaskAttributes;
  }>;
}
interface DropdownState {
  selectedRooms: string[];
  tempSelectedRooms: string[];
  searchText: string;
  isDropdownOpen: boolean;
}

interface Invoice {
  id: string;
  invoiceName: string;
  dueDate: string;
  invoicePrice: string;
  retainedAmount: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  projectId:string;
  planId:string;
  ProjectDetails :ProjectDetails;
  selectedTaskId:string | null;
  roomsAndTaskListsCard: TaskListDataItem
  viewAllTasks:boolean;
  openDialog:boolean;
  taskId:string;
  openSelectPaymentDialog:boolean;
  selectPlanValue:string;
  selectPlanIdData:any;
  openMilestonesPaymentPlanDialog:boolean;
  openValuationsPaymentPlanDialog:boolean;
  PaymentOptionChangeValue:string;
  dropdownKeys: string[];
  dropdowns: Record<string, DropdownState>;
  invoices: any;
  Adjusted_Openmodel:boolean;
  Edit_AdjustmentOpenmodel:boolean;
  PaymentPlanDialog:string;
  EditAjustPaymentPlanDialog:string;
  EditAjustPaymentPlanModeDialog:string;
  AdjustAmout:string;
  RemoveAdjustmentOpenmodel:boolean;
  hasError: boolean,
  Discount_Amount:number,
  Total_new_cast:number,
  dummydata: string[];


  mileStoneData:any;
  rententionPercentageValue:any;



  // Customizable Area End

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProjectByIdCallId:string = "";
  getprojectapi:string = "";
  apiGetTaskListCallId:string = "";
  apiGetTaskListByIdCallId:string = "";
  apiGetMileStoneId:string="";
  apiHitOnSelectPayload:string="";
  apihandleRoomCostList:string="";
  apihandleRoomCostPaymentTime:string="";
  apiSaveplanHit:string="";
  saveAdjustCostdataCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

     
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token:'',
      projectId:'',
      planId:'',
      ProjectDetails : {} as ProjectDetails,
      selectedTaskId:null,
      roomsAndTaskListsCard: { data: [] },
      viewAllTasks:false,
      openDialog:false,
      taskId:'',
      openSelectPaymentDialog:false,
      selectPlanValue:'',
      selectPlanIdData:'',
      openMilestonesPaymentPlanDialog:false,
      openValuationsPaymentPlanDialog:false,
      PaymentOptionChangeValue:'',
      rententionPercentageValue:'',
      dropdownKeys: [],
      dropdowns: {},
      invoices: [],
      Adjusted_Openmodel:false,
      Edit_AdjustmentOpenmodel:false,
      PaymentPlanDialog:"",
      EditAjustPaymentPlanDialog:"",
      EditAjustPaymentPlanModeDialog:"",
      AdjustAmout:"",
      RemoveAdjustmentOpenmodel:false,
      hasError: false,
      Discount_Amount:0.00,
      Total_new_cast:0.00,
      dummydata:[],



      mileStoneData:[],

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetProjectByIdCallId) {
        this.getProjectByIdResponse(responseJson);
       
      }
      if (apiRequestCallId === this.getprojectapi) {
       this.setState({dummydata:responseJson})
       
      }
      if (apiRequestCallId === this.saveAdjustCostdataCallId) {
        if(responseJson.status==="success"){
          this.Adjusted_paymentEditModelclose()          
        }
        
      }
    }
    if(apiRequestCallId === this.apiGetMileStoneId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleMileStoneApiData(responseJson)
    }
    if(apiRequestCallId === this.apihandleRoomCostList){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleRoomListData(responseJson)
    }
   
    
  }
  this.receive1(message);
  this.receive2(message);
   // Customizable Area End
    }
  // Customizable Area Start
 
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListCallId) {
          this.getTaskListResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListByIdCallId) {
          this.getTaskListByIdResponse(responseJson); 
        }
      }
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apihandleRoomCostPaymentTime) {
          this.handleRoomListData(responseJson); 
          this.handleResponseListDropDown(responseJson)
        }
      }    
    }

  }
  async componentDidMount() {
    let token = await getStorageData("token");
    this.setState({ token: token });
    this.getIds();
    this.getProjectDetailsById();
    this.getTaskList();
    this.getMileStoneApisCall();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleResponseListDropDown =(responseJson:any)=>{

    const apiResponse:any = responseJson?.data?.data?.map((item:any) => ({
      id: item.id,
      invoiceName: item.attributes.room_name,
      dueDate: item.attributes.due_date ? item.attributes.due_date : null, 
      invoicePrice: item.attributes.room_cost.room_cost.toString(), 
      retainedAmount: item.attributes.room_cost.retention_amount === 0 ? 'Auto-calculate' : item.attributes.room_cost.retention_amount.toString(),
    }));

    const dropdownKeys = apiResponse?.map((invoice:any) => invoice.id);
    const dropdowns = dropdownKeys.reduce((acc: Record<string, DropdownState>, key:any) => {
      acc[key] = {
        selectedRooms: [],
        tempSelectedRooms: [],
        searchText: '',
        isDropdownOpen: false,
      };
      return acc;
    }, {});

    this.setState({ dropdownKeys, dropdowns });
  }
  getIds() {
    let url = window.location.pathname;
    let parts = url.split("/");
  
    if (parts.length >= 3) {
      const planId = parts[2];
  
      if (parts.length >= 5 && parts[3] === "project_id") {
        const projectId = parts[4];
        this.setState({ planId: planId, projectId: projectId });
        return { planId, projectId };
      }
     
      this.setState({ planId: planId, projectId: '' });
      return { planId, projectId: null };
    }
    return { planId: null, projectId: null };
  }

async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedTaskId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedTaskId: null });
  }
};
handleOpenActiaonTab = (id: string) => {
    this.setState(prevState => ({
        selectedTaskId: prevState.selectedTaskId === id ? null : id
    }));
}
handleDeleteTaskList=(task_id:any)=>{
    this.setState({openDialog:true,taskId:task_id});
}
handleEditTaskList=(task_id:any)=>{
    console.warn("Edit task_id : ",task_id);

}
getProjectDetailsById=()=>{
  const header = {
  "Content-Type": configJSON.getProjectDetailsContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetProjectByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getMileStoneApisCall=()=>{
  const header = {
    "Content-Type": configJSON.getProjectDetailsContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetMileStoneId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.mileStoneOptionsApi}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskList=()=>{
  const header = {
  "Content-Type": configJSON.getContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskListAPIEndPoint}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskById=(taskId:string)=>{
  const header = {
  "Content-Type": configJSON.getProjectDetailsContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskByIdAPIEndPoint}/${taskId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProjectByIdResponse=(responseJson:any)=>{
  const country = Country.getCountryByCode(responseJson.data.attributes.addressable.country);
  const countryName = country?.name
  const state = State.getStateByCodeAndCountry(responseJson.data.attributes.addressable.state, responseJson.data.attributes.addressable.country);
  const stateName = state?.name

 this.setState({
ProjectDetails:{
  projectName: responseJson.data.attributes.project_name,
  clientFirstName: responseJson.data.attributes.addressable.name,
  clientLastName: responseJson.data.attributes.addressable.last_name,
  clinetEmail: responseJson.data.attributes.addressable.email,
  clientStreetName: responseJson.data.attributes.addressable.address,
  clientCityName: responseJson.data.attributes.addressable.city,
  clientStateName: stateName,
  clientCountryName: countryName,
  clientPostCode: responseJson.data.attributes.addressable.post_code,
  totalCost: "",
  adjustedCost: ""
}
 })
}

handleMileStoneApiData=(data:any)=>{
  this.setState({
    mileStoneData:data?.data,
  })
}

getTaskListResponse = (responseJson: any) => {
  if (responseJson.errors?.[0]?.token) {
    alert("Token is expired, Please login !!!");
    this.props.navigation.navigate('EmailAccountLoginBlock');
  } else {
    this.setState({
      roomsAndTaskListsCard: responseJson,
    });
  }
};
getTaskListByIdResponse = (responseJson:any)=>{
  console.warn(responseJson);
}
handelViewAll = ()=>{
  this.setState((prevState) => ({ viewAllTasks: !prevState.viewAllTasks }));
}
handleCloseDialog=()=>{
  this.setState({openDialog:false})
}
handleConfirmDelete=()=>{
  this.setState({openDialog:false,taskId:''});
}
handleOpenSelectPaymentDialog=()=>{
  this.setState({openSelectPaymentDialog:true});
}
handleCloseSelectPaymentPlanDialog=()=>{
  this.setState({openSelectPaymentDialog:false});
}
handleRadioChange = (value:any,id:any) => {
  this.setState({
    selectPlanValue:value,
    selectPlanIdData:id
  });
};
handleCloseMilestonesPaymentPlanDialog=()=>{
  this.setState({openMilestonesPaymentPlanDialog:false,PaymentOptionChangeValue:'',selectPlanValue:''});
}
handleCloseValuationsPaymentPlanDialog=()=>{
  this.setState({openValuationsPaymentPlanDialog:false,PaymentOptionChangeValue:'',selectPlanValue:''});

}
handleSubmitSelectPaymentPlan = (event:any) =>{
  event.preventDefault();
  if (this.state.selectPlanValue === 'Milestones') {
    this.setState({openSelectPaymentDialog:false,openMilestonesPaymentPlanDialog:true})
  } else if (this.state.selectPlanValue === 'Valuations') {
    this.setState({openSelectPaymentDialog:false,openValuationsPaymentPlanDialog:true})
  }
  this.handleHitPutApiForPlan()
  this.handleRoomCostListAPI()
}

handleSubmit = (values: any) =>{
  if(this.state.selectPlanValue === "Valuations"){

    const invoices = Object.values(this.state.dropdowns).map((room: any) => {
      const roomDataFromAPI = this.state.invoices?.find((apiRoom: any) => apiRoom?.attributes?.room_id === room.room_id);
    
      return {
        project_room_ids: room.tempSelectedRooms,
        retention_amount: roomDataFromAPI ? roomDataFromAPI?.attributes?.room_cost.retention_amount : 0,
        due_date: roomDataFromAPI ? roomDataFromAPI?.attributes?.due_date : null,
        total_amount: roomDataFromAPI ? roomDataFromAPI?.attributes?.room_cost.room_cost : 0
      };
    });
  
  
    const payload ={
      project_invoice:{
        retention_percentage:Number(this.state.rententionPercentageValue),
        invoices:invoices
      }
    }
    const header = {
      "Content-Type": configJSON.getProjectDetailsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSaveplanHit = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePlanHitAPI}/${this.state.projectId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  else{
  const responseValue = this.state.invoices?.map((val:any)=>{

    const IdValue = val?.id

    
    return{
        project_room_ids:[IdValue],
        retention_amount: val ? val.attributes.room_cost.retention_amount : 0,
        due_date: val ? val.attributes.due_date : null,
        total_amount: val ? val.attributes.room_cost.room_cost : 0
    }
  })

  const payload ={
    project_invoice:{
      retention_percentage:Number(this.state.rententionPercentageValue),
      invoices:responseValue
    }
  }
  const header = {
    "Content-Type": configJSON.getProjectDetailsContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiSaveplanHit = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savePlanHitAPI}/${this.state.projectId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }
  this.setState({
    openMilestonesPaymentPlanDialog:false,
    openValuationsPaymentPlanDialog:false
  })
}
handlePaymentOptionChange=(value:string)=>{
  if(value==='pay_after'){
    this.setState((pre)=>{
      return{
        PaymentOptionChangeValue:'pay_after',
        rententionPercentageValue:pre.rententionPercentageValue
      }});
  }else if(value==='pay_before'){
    this.setState((pre)=>{
      return{
        PaymentOptionChangeValue:'pay_before',
        rententionPercentageValue:pre.rententionPercentageValue
      }})  }
  this.handlePaymentOptionStatusApi(value)
}

handlePaymentOptionStatusApi =(value:any)=>{
  const header = {
    "Content-Type": configJSON.getProjectDetailsContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apihandleRoomCostPaymentTime = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.roomCostListAPI}/${this.state.projectId}?due_date=${value}&retention=${this.state.rententionPercentageValue}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
 
  handleDropdownOpen = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          isDropdownOpen: true,
          tempSelectedRooms: [],
        },
      },
    }));
  };

  handleDropdownClose = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          isDropdownOpen: false,
          searchText: '',
        },
      },
    }));
  };

  handleCheckboxToggle = (key: string, roomId: string) => {

    this.setState((prevState) => {
      const { tempSelectedRooms } = prevState.dropdowns[key];
      const updatedTempSelectedRooms = tempSelectedRooms.includes(roomId)
        ? tempSelectedRooms.filter((id) => id !== roomId)
        : [...tempSelectedRooms, roomId];

      return {
        dropdowns: {
          ...prevState.dropdowns,
          [key]: {
            ...prevState.dropdowns[key],
            tempSelectedRooms: updatedTempSelectedRooms,
            selectedRooms:updatedTempSelectedRooms
          },
        },
      };
    });
  };

  handleSearchChange = (key: string, searchText: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          searchText,
        },
      },
    }));
  };

  handleAdd = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          selectedRooms: [...prevState.dropdowns[key].tempSelectedRooms],
          isDropdownOpen: false,
          searchText: '',
        },
      },
    }));
  };

  handleCancel = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          tempSelectedRooms: [],
          isDropdownOpen: false,
          searchText: '',
          selectedRooms:[]
        },
      },
    }));
  };

  handleHitPutApiForPlan=()=>{
    const header = {
      "Content-Type": configJSON.getProjectDetailsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHitOnSelectPayload = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nextBtnClickProjectId}/${this.state.projectId}?plan_id=${this.state.selectPlanIdData}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRoomCostListAPI=()=>{
    const header = {
      "Content-Type": configJSON.getProjectDetailsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apihandleRoomCostList = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.roomCostListAPI}/${this.state.projectId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRoomListData=(responseJson:any)=>{
    const dataValue = responseJson.data.data;
      this.setState({
        invoices:dataValue
      })
  }

  handleSwitchPaymentPlan = (value:string) =>{
    if (value === 'Milestones') {
      this.setState({openValuationsPaymentPlanDialog:false,openMilestonesPaymentPlanDialog:true})
    } else if (value === 'Valuations') {
      this.setState({openMilestonesPaymentPlanDialog:false,openValuationsPaymentPlanDialog:true})
    } 
  }
  editPlan=()=>{
    this.setState({openValuationsPaymentPlanDialog:true})

  }

  backNavigation = ()=>{
    const pathname = window.location.pathname
    this.backBtnQuoteOverviewClicked()
    const { planID , projectID }= this.extractIdsFromUrl(pathname)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${planID}/project_id/${projectID}`);
    this.send(message);
  }


  extractIdsFromUrl = (url:string)=>{
    const paths = url.split("/")
    const planID = paths[2]
    const projectID = paths[4]
    return {planID,projectID}
  }

  backBtnQuoteOverviewClicked = async ()=>{
    await setStorageData("backBtnQuoteOverviewClick",JSON.stringify(true))
  }
  AdjustpaymentOpenmodel(){
    this.setState({Adjusted_Openmodel:true})
  
  }
  Adjusted_paymentModelclose=()=>{
    this.setState({Adjusted_Openmodel:false})
    this.RemoveAdjustOpenmodel()
    
  }
  AdjustpaymentConfirm(){
    this.setState({Adjusted_Openmodel:false})
    this.AdjustpaymenEdittOpenmodel()
  
  }
  AdjustpaymenEdittOpenmodel(){
    this.setState({Edit_AdjustmentOpenmodel:true})
  
  }
  Adjusted_paymentEditModelclose=()=>{
    this.setState({Edit_AdjustmentOpenmodel:false})
  }
  AdjustpaymentEditConfirm(){
  this.saveAdjustcoastData()
  }
  EdithandlePaymentOptionChange=(value:string)=>{
    if(value==='add_discount'){
      this.setState({EditAjustPaymentPlanDialog:'add_discount'});
    }else if(value==='add_additional_cost'){
      this.setState({EditAjustPaymentPlanDialog:'add_additional_cost'});
    }
  }
SelectPaymentOptionModeChange=(value:string)=>{
  this.setState({
    Discount_Amount: 0,
          Total_new_cast: 0,
  })
    if(value==='percentage'){
      this.setState({EditAjustPaymentPlanModeDialog:'percentage',AdjustAmout:"",hasError:false});
    }else if(value==='fixed'){
      this.setState({EditAjustPaymentPlanModeDialog:'fixed',AdjustAmout:"",hasError:false});
    }
  }
  calculatePercentage(value: any | number, percentage: number) {
    return (value * percentage) / 100;

}
AdjustAmoutInputChange = (value: string) => {
  let originValue = this.state.EditAjustPaymentPlanModeDialog === "percentage" ? 100 : 50000;
  const numericValue = parseFloat(value);

  if (isNaN(numericValue)) {
      this.setState({ AdjustAmout: value, hasError: true });
      return;
  }

  if (this.state.EditAjustPaymentPlanModeDialog === "percentage") {
      const percentageValue = this.calculatePercentage(numericValue, 50000);
      const totalNewCast = 50000 - percentageValue;

      this.setState({
          AdjustAmout: value,
          hasError: numericValue > originValue, 
          Discount_Amount: percentageValue,
          Total_new_cast: totalNewCast,
      });
  } else {
      const totalNewCast = originValue - numericValue;

      this.setState({
          AdjustAmout: value,
          hasError: numericValue > originValue, 
          Discount_Amount: numericValue,
          Total_new_cast: totalNewCast,
      });
  }

  if (numericValue >= originValue) {
    if(numericValue===0){
      this.setState({ hasError: false });

    }
    this.setState({ hasError: true });
  } else {
      this.setState({ hasError: false });
  }
};

  RemoveAdjustOpenmodel(){
    this.setState({RemoveAdjustmentOpenmodel:true})

  }
  REmoveAdjustedModelclose=()=>{
    this.setState({RemoveAdjustmentOpenmodel:false})
  }
  RemoveAdjustConfirm(){
   
  }
  GenerateQuote = ()=>{
    
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectProposalgeneration"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), "");
    this.send(message);
  }
  getprojectapiid=()=>{
    const header = {
    "Content-Type": configJSON.getProjectDetailsContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getprojectapi = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveAdjustcoastData = async () => {
      const project_id = await getStorageData("project_id");
             const header = {
              "Content-Type":"application/json",
              token: this.state.token,
            };
      const body = {
          "adjustment_type": this.state.EditAjustPaymentPlanDialog,
          "adjustment_mode": this.state.EditAjustPaymentPlanModeDialog,
          "value": this.state.AdjustAmout
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.saveAdjustCostdataCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_centralized_billings/project_cost_adjustments/${project_id}/adjust_cost`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      
    
  };
  // Customizable Area End
}